<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">
                        Novidades
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 200"
                            @click:row="open"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :search="search"
                            :options.sync="options"
                        >
                            <template v-slot:item.created_at="{ item }">
                                {{ timeAgo(item.created_at) }}
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-btn icon color="primary">
                                    <v-icon @click.stop="open(item)">mdi-eye</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                        <Paginate
                            v-model="pagination"
                            @input="consultar"
                        />
                    </v-card-text>
                </v-card>
            </v-content>

            <v-dialog v-model="visible" persistent max-width="500px">
                <v-card>
                    <v-card-title 
                        class="justify-space-between align-center" 
                        style="background-color: #f5f5f5;"
                    >
                        <span 
                            v-html="novidade.titulo" 
                            class="text-h6 font-weight-bold"
                        />
                        <v-btn icon @click="visible = false" color="grey darken-1">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    
                    <v-divider/>
                    
                    <v-card-text class="py-4 px-6">
                        <span 
                            v-html="novidade.descricao" 
                            style="font-size: 16px; line-height: 1.5; color: #666;"
                        />
                    </v-card-text>
                    
                    <v-divider/>
                    
                    <v-card-actions class="justify-end px-4 pb-4">
                        <v-btn text color="primary" @click="visible = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Paginate from '@/components/Paginate';

export default {
    name: 'Novidades',

    components: {
        BaseContainer,
        Paginate,
    },

    data: () => ({
        search: '',
        loading: true,
        dados: [],
        headers: [
            { text: 'Titulo', value: 'titulo' },
            { text: 'Data/Hora', value: 'created_at' },
            { text: '', value: 'action', sortable: false, align: 'end'},
        ],
        novidade: {},
        visible: false,
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        options: {
            handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];

            const params = this.getParams();

            this.$http.get('novidades?' + params).then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
                this.loading = false;
            });
        },

        getParams() {
            const params = new URLSearchParams();

            params.append('page', this.pagination.current_page);

            if (this.options.sortBy.length) {
                params.append('sortField', this.options.sortBy[0]);
            }
            if (this.options.sortDesc.length) {
                params.append('sortOrder', this.options.sortDesc[0] ? 1 : -1);
            }

            return params.toString();
        },

        close() {
            this.visible = false;
            this.novidade = {};
        },

        open(row) {
            this.novidade = row;
            this.visible = true;
        },
    },
}
</script>
